<template>
    <div class="ModifyInventory">
        <el-page-header @back="$router.go(-1)" content="修改库存"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <!--  -->
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <div class="f-w boxShadow pt-60 pl-40" style="width: 95.5%;border-radius: 10px;">
                    <el-form ref="InventoryForm" :model="InventoryForm" label-width="110px"
                        class="ProductLibrary_Add_Form f-w" :rules="InventoryFormRules" :label-position="'right'">
                        <div class="formTitle ">产品基本信息</div>
                        <el-form-item class=" w--50" label="产品名称：" prop="productName">
                            <el-input style="width: 340px;" disabled v-model.trim="productInventoryBaseInfo.productName"
                                placeholder="请输入产品名称"></el-input>
                        </el-form-item>
                        <el-form-item class=" w--50" label="内部编号：" prop="productNumber">
                            <el-input style="width: 340px;" disabled v-model.trim="productInventoryBaseInfo.productNumber"
                                placeholder="请输入内部编号">
                            </el-input>
                        </el-form-item>
                        <el-form-item class=" w--50" label="材质类型：" prop="attributeValue2">
                            <el-input style="width: 340px;" disabled
                                v-model.trim="productInventoryBaseInfo.productAttribute.attributeValue2"
                                placeholder="请输入内部编号">
                            </el-input>
                        </el-form-item>
                        <el-form-item class=" w--50" label="库存数量：" prop="inventoryQuantity">
                            <el-input style="width: 340px;" disabled
                                v-model.trim="productInventoryBaseInfo.inventoryQuantity" placeholder="请输入内部编号">
                            </el-input>
                        </el-form-item>
                        <div class="formTitle ">变更信息</div>
                        <el-form-item class="w--50" label="变更类型">
                            <el-select v-model="ChangeType" @change="selectChangeType" style="width: 340px;">
                                <el-option label="入库" value="入库"></el-option>
                                <el-option label="出库" value="出库"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="w--50" label="变更原因：">
                            <el-select style="width: 340px;" v-model="InventoryForm.stockCtype" @change="selectStockCtype">
                                <el-option v-for="(item, index) of selectOptions" :label="item.label" :value="item.value"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="w--50" label="产品单价" prop="ProductUnitPrice">
                            <DecimalInput style="width: 340px;" :placeholderText="'请输入产品单价'"
                                :modelValue="InventoryForm.ProductUnitPrice" @DecimalInputEvent="ProductUnitPriceChange">
                            </DecimalInput>
                        </el-form-item>
                        <el-form-item class="w--50" label="变更数量：" prop="stockChangeNumber">
                            <DecimalInput style="width: 340px;" :placeholderText="'请输入变更数量'"
                                :modelValue="InventoryForm.stockChangeNumber" @DecimalInputEvent="stockChangeNumberChange">
                            </DecimalInput>
                        </el-form-item>
                        <el-form-item class="w--50" label="变更金额：" prop="amountChangeNumber">
                            <DecimalInput style="width: 340px;" :placeholderText="'请输入变更金额'"
                                :modelValue="InventoryForm.amountChangeNumber" @DecimalInputEvent="amountChangeNumberChange"
                                :typeValue="'amountChangeNumber'" :maxlength="24" :maxValue="'999999999999999999999.99'">
                            </DecimalInput>
                        </el-form-item>
                        <el-form-item class="w--50" label="货币类型" prop="valuationCurrency">
                            <SelectOption style="width: 340px;" :selectModel="InventoryForm.valuationCurrency"
                                :Option="CurrencyOption" :type="'ValuationCurrency'"
                                @selectValuationCurrency="selectValuationCurrency">
                            </SelectOption>
                        </el-form-item>
                        <template v-if="(ChangeType == '入库' && showMore == true) || showOutMore == true">
                            <el-form-item class="w--50" label="供应商：" prop="sdName">
                                <ChineseCharactersInput style="width: 340px;" :modelValue="InventoryForm.sdName"
                                    :type="'sdName'" :placeholderValue="'请输入供应商'" :maxlength="'30'" @getValue="getValue">
                                </ChineseCharactersInput>
                            </el-form-item>
                            <el-form-item class="w--50" label="联系电话：" prop="sdPhone">
                                <PhoneInput style="width: 340px;" :modelValue="InventoryForm.sdPhone"
                                    :placeholderValue="'请输入联系电话'" @getPhoneNumber="getPhoneNumber" :LengthLimit="'16'"
                                    :maxlength="16">
                                </PhoneInput>
                            </el-form-item>
                            <el-form-item class="w--50" label="地区：" prop="sdCity">
                                <ChooseCity style="width: 340px;" :city="InventoryForm.sdCity"
                                    @handleChangeCity="handleChangeCity" :key="new Date().getTime()"></ChooseCity>
                            </el-form-item>
                            <el-form-item class="w--50" label="联系人：" prop="sdContact">
                                <ChineseCharactersInput style="width: 340px;" :modelValue="InventoryForm.sdContact"
                                    :type="'sdContact'" :placeholderValue="'请输入联系人'" :maxlength="'30'" @getValue="getValue">
                                </ChineseCharactersInput>
                            </el-form-item>
                            
                            <el-form-item class="w--50" label="详细地址：" prop="sdAddress">
                                <ChineseCharactersInput style="width: 810px;" :modelValue="InventoryForm.sdAddress"
                                    :type="'sdAddress'" :placeholderValue="'如街道、门牌号、大厦、写字楼等'" :maxlength="'30'"
                                    @getValue="getValue">
                                </ChineseCharactersInput>
                            </el-form-item>
                            <el-form-item class="w--100" v-show="ChangeType == '出库'" label="退回单据备注" prop="stockReturnNote">
                                <TextArea class="fs-18" :placeholderValue="'请输入备注信息'" :modelValue="InventoryForm.stockReturnNote"
                                    :type="'stockReturnNote'" :maxlengthValue="300" @getTextAreaValue="getTextAreaValue"
                                    :TextAreaisLimit="true"></TextArea>
                            </el-form-item>
                        </template>
                        <el-form-item class="w--100" label="备注信息" prop="stockChangeNote">
                            <TextArea class="fs-18" style="width: 810px;" :placeholderValue="'请输入备注信息'" :modelValue="InventoryForm.stockChangeNote"
                                :type="'stockChangeNote'" @getTextAreaValue="getTextAreaValue"
                                :TextAreaisLimit="true"></TextArea>
                        </el-form-item>
                        <el-form-item  class="w--100 f-c-c">
                            <div class="submitBtn" @click="updateProductStock('InventoryForm')">提交</div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <!-- <el-form ref="InventoryForm" :model="InventoryForm" label-width="110px" class="ProductLibrary-Add-Form f-w"
            :rules="InventoryFormRules">
            <div class="formTitle ">产品基本信息</div>
            <el-form-item class=" w--50" label="产品名称：" prop="productName">
                <el-input  style="width: 340px;" disabled v-model.trim="productInventoryBaseInfo.productName" placeholder="请输入产品名称"></el-input>
            </el-form-item>
            <el-form-item class=" w--50" label="内部编号：" prop="productNumber">
                <el-input style="width: 340px;" disabled v-model.trim="productInventoryBaseInfo.productNumber" placeholder="请输入内部编号">
                </el-input>
            </el-form-item>
            <el-form-item class=" w--50" label="材质类型：" prop="attributeValue2">
                <el-input style="width: 340px;" disabled v-model.trim="productInventoryBaseInfo.productAttribute.attributeValue2"
                    placeholder="请输入内部编号">
                </el-input>
            </el-form-item>
            <el-form-item class=" w--50" label="库存数量：" prop="inventoryQuantity">
                <el-input style="width: 340px;" disabled v-model.trim="productInventoryBaseInfo.inventoryQuantity" placeholder="请输入内部编号">
                </el-input>
            </el-form-item>
            <el-form-item label="变更信息" style="font-weight: bold;"></el-form-item>
            <el-form-item label="变更类型">
                <el-select v-model="ChangeType" @change="selectChangeType">
                    <el-option label="入库" value="入库"></el-option>
                    <el-option label="出库" value="出库"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="变更原因：">
                <el-select v-model="InventoryForm.stockCtype" @change="selectStockCtype">
                    <el-option v-for="(item, index) of selectOptions" :label="item.label" :value="item.value"
                        :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品单价" prop="ProductUnitPrice">
                <DecimalInput :placeholderText="'请输入产品单价'" :modelValue="InventoryForm.ProductUnitPrice"
                    @DecimalInputEvent="ProductUnitPriceChange"></DecimalInput>
            </el-form-item>
            <el-form-item label="变更数量：" prop="stockChangeNumber">
                <DecimalInput :placeholderText="'请输入变更数量'" :modelValue="InventoryForm.stockChangeNumber"
                    @DecimalInputEvent="stockChangeNumberChange"></DecimalInput>
            </el-form-item>
            <el-form-item label="变更金额：" prop="amountChangeNumber">
                <DecimalInput :placeholderText="'请输入变更金额'" :modelValue="InventoryForm.amountChangeNumber"
                    @DecimalInputEvent="amountChangeNumberChange" :typeValue="'amountChangeNumber'" :maxlength="24"
                    :maxValue="'999999999999999999999.99'">
                </DecimalInput>
            </el-form-item>
            <el-form-item label="货币类型" prop="valuationCurrency">
                <SelectOption :selectModel="InventoryForm.valuationCurrency" :Option="CurrencyOption"
                    :type="'ValuationCurrency'" @selectValuationCurrency="selectValuationCurrency">
                </SelectOption>
            </el-form-item>

            <template v-if="(ChangeType == '入库' && showMore == true) || showOutMore == true">
                <el-form-item label="供应商：" prop="sdName">
                    <ChineseCharactersInput :modelValue="InventoryForm.sdName" :type="'sdName'" :placeholderValue="'请输入供应商'"
                        :maxlength="'30'" @getValue="getValue">
                    </ChineseCharactersInput>
                </el-form-item>
                <el-form-item label="联系电话：" prop="sdPhone">
                    <PhoneInput :modelValue="InventoryForm.sdPhone" :placeholderValue="'请输入联系电话'"
                        @getPhoneNumber="getPhoneNumber" :LengthLimit="'16'" :maxlength="16">
                    </PhoneInput>
                </el-form-item>
                <el-form-item label="联系人：" prop="sdContact">
                    <ChineseCharactersInput :modelValue="InventoryForm.sdContact" :type="'sdContact'"
                        :placeholderValue="'请输入联系人'" :maxlength="'30'" @getValue="getValue">
                    </ChineseCharactersInput>
                </el-form-item>
                <el-form-item label="地区：" prop="sdCity">
                    <ChooseCity :city="InventoryForm.sdCity" @handleChangeCity="handleChangeCity"
                        :key="new Date().getTime()"></ChooseCity>
                </el-form-item>
                <el-form-item label="详细地址：" prop="sdAddress">
                    <ChineseCharactersInput :modelValue="InventoryForm.sdAddress" :type="'sdAddress'"
                        :placeholderValue="'如街道、门牌号、大厦、写字楼等'" :maxlength="'30'" @getValue="getValue">
                    </ChineseCharactersInput>
                </el-form-item>
                <el-form-item v-show="ChangeType == '出库'" label="退回单据备注" prop="stockReturnNote">
                    <TextArea :placeholderValue="'请输入备注信息'" :modelValue="InventoryForm.stockReturnNote"
                        :type="'stockReturnNote'" :maxlengthValue="300" @getTextAreaValue="getTextAreaValue"
                        :TextAreaisLimit="true"></TextArea>
                </el-form-item>
            </template>
            <el-form-item label="备注信息" prop="stockChangeNote">
                <TextArea :placeholderValue="'请输入备注信息'" :modelValue="InventoryForm.stockChangeNote"
                    :type="'stockChangeNote'" @getTextAreaValue="getTextAreaValue" :TextAreaisLimit="true"></TextArea>
            </el-form-item>
            <el-form-item class="One-btn">
                <el-button type="primary" @click="updateProductStock('InventoryForm')">提交</el-button>
            </el-form-item>
        </el-form> -->
    </div>
</template>

<script>
import { CurrencyOption } from '@/utils/SelectOption'
import { InventoryFormRules } from "@/utils/FormRules"

export default {
    name: 'ModifyInventory',
    inject: ["reload"],
    components: {
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
    },
    data() {
        return {
            productInventoryBaseInfo: {},
            ChangeType: '入库',
            showMore: true,//采购入库展示
            showOutMore: false,//出库退回供应商显示
            InventoryForm: {
                ProductUnitPrice: 0,//产品单价
                amountChangeNumber: 0,//变更金额
                stockChangeNumber: 0,//库存变更数量
                stockChangeNote: '',//库存变更备注
                stockReturnNote: '',//退回单据备注
                sdName: '',//供应商
                sdContact: '',//联系人
                sdPhone: '',//联系电话
                sdCity: '',//地区
                sdAddress: '',//详细地址
                valuationCurrency: '￥',//计价货币
                stockCtype: 'INP0'
            },
            InventoryFormRules,
            CurrencyOption: CurrencyOption,//货币类型
            selectOptions: [{
                label: '采购入库',
                value: 'INP0'
            }, {
                label: '其他',
                value: 'INP1'
            }],
            intOptions: [{//入库
                label: '采购入库',
                value: 'INP0'
            }, {
                label: '其他',
                value: 'INP1'
            }],
            outOptions: [{//出库
                label: '产品报废',
                value: 'OUT0'
            }, {
                label: '退回供应商',
                value: 'OUT2'
            }, {
                label: '其他',
                value: 'OUT1'
            }],
            isShowStar: false,
            enterpriseStateInfo: {}
        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
        this.productInventoryBaseInfo = this.$store.state.productInventoryBaseInfo;
        this.productInventoryBaseInfo.productNumber = this.productInventoryBaseInfo.productNumber ? this.productInventoryBaseInfo.productNumber : '未设置';
        this.productInventoryBaseInfo.productAttribute.attributeValue2 = this.productInventoryBaseInfo.productAttribute.attributeValue2 ? this.productInventoryBaseInfo.productAttribute.attributeValue2 : '未设置';
    },
    methods: {
        getPhoneNumber(data) {
            this.InventoryForm.sdPhone = data.value;
        },
        // 获取textarea内容
        getTextAreaValue(data) {
            this.InventoryForm[data.type] = data.value
        },
        getValue(data) {
            this.InventoryForm[data.type] = data.value
        },
        // 产品单价变化
        ProductUnitPriceChange(data) {
            let stockChangeNumber = this.InventoryForm.stockChangeNumber
            this.InventoryForm.ProductUnitPrice = data.value
            this.InventoryForm.amountChangeNumber = Number(stockChangeNumber * data.value).toFixed(2)
        },
        // 变更数量变化
        stockChangeNumberChange(data) {
            let ProductUnitPrice = this.InventoryForm.ProductUnitPrice
            this.InventoryForm.stockChangeNumber = data.value
            this.InventoryForm.amountChangeNumber = Number(ProductUnitPrice * data.value).toFixed(2)
        },
        // 变更金额改变
        amountChangeNumberChange(data) {
            this.InventoryForm.amountChangeNumber = data.value
        },
        // 切换变更类型
        selectChangeType(value) {
            if (value == '出库') {
                this.selectOptions = this.outOptions
                this.InventoryForm.stockCtype = 'OUT0';//产品报废
                this.showOutMore = false
                this.InventoryForm.stockReturnNote = '';
            } else {//入库
                this.showMore = true;//初始化显示更多
                this.showOutMore = false;//初始化出库的变量
                this.selectOptions = this.intOptions
                this.InventoryForm.stockCtype = 'INP0'//采购入库
                this.InventoryForm.stockReturnNote = '';
                this.InventoryFormRules = InventoryFormRules
                // 入库方面供应商，联系电话，联系人非必填，只有出库退回供应商才需要必填
                this.InventoryFormRules.sdName = [{ required: false }]
                this.InventoryFormRules.sdPhone = [{ required: false }]
                this.InventoryFormRules.sdContact = [{ required: false }]
                this.reload()
            }
            this.ChangeType = value
        },
        // 切换变更原因
        selectStockCtype(value) {
            if (this.ChangeType == '入库') {
                value == 'INP1' ? this.showMore = false : this.showMore = true;
                let { InventoryFormRules } = this;
                Object.remove(InventoryFormRules, "sdName");
                Object.remove(InventoryFormRules, "sdPhone");
                Object.remove(InventoryFormRules, "sdContact");
                Object.remove(InventoryFormRules, "sdCity");
                Object.remove(InventoryFormRules, "sdAddress");
            } else {
                if (value == 'OUT2') {
                    this.showOutMore = true
                    this.InventoryFormRules.sdName = [{ required: true, message: "请输入供应商", trigger: "blur" }]
                    this.InventoryFormRules.sdPhone = [{ required: true, message: "请输入联系电话", trigger: "blur" }]
                    this.InventoryFormRules.sdContact = [{ required: true, message: "请输入联系人", trigger: "blur" }]
                    this.InventoryFormRules.sdCity = [{ required: true, message: "请选择地区", trigger: "blur" }]
                    this.InventoryFormRules.sdAddress = [{ required: true, message: "请填写详细地址", trigger: "blur" }]
                    // sdAddress
                    this.$CommonRequests.getEnterpriseOrderTemplate('RS').then((res) => {
                        this.InventoryForm.stockReturnNote = res.data.enterpriseNote
                    })
                } else {
                    this.InventoryForm.stockReturnNote = '';
                    this.showOutMore = false;
                    this.InventoryFormRules = InventoryFormRules
                }

            }
            this.InventoryForm.stockCtype = value;
        },
        // 选中省市区
        handleChangeCity(value) {
            this.InventoryForm.sdCity = value;
        },
        // 货币类型
        selectValuationCurrency(value) {
            this.InventoryForm.valuationCurrency = value;
        },
        // 修改产品库存
        updateProductStock(form) {
            let that = this,
                { productId } = that.productInventoryBaseInfo,
                { amountChangeNumber, stockChangeNumber, stockChangeNote, sdName, stockReturnNote,
                    sdContact, sdPhone, sdCity, sdAddress, valuationCurrency, stockCtype } = that.InventoryForm,
                checkCity = sdCity ? that.$common.checkCityArr(sdCity) : sdCity;
            let phoneRule = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
            if (sdPhone && !phoneRule.test(sdPhone)) {
                return that.$message('请填写正确的手机号码')
            }
            that.$refs[form].validate((valid) => {
                if (valid) {
                    that.$confirm('是否修改产品库存', '提示').then(() => {
                        that.$http.updateProductStock({
                            changeUserId: parseInt(that.$store.state.userId),
                            token: that.$getStorage('token'),
                            productId,
                            amountChangeNumber: Number(amountChangeNumber),
                            stockChangeNumber: Number(stockChangeNumber),
                            stockChangeNote,//库存修改备注
                            stockReturnNote,//退回单据备注
                            sdName,
                            sdContact,
                            sdPhone,
                            sdCity: checkCity,
                            sdAddress, valuationCurrency, stockCtype
                        }).then(res => {
                            if (res.code == 200) {
                                that.$common.message(res.msg, 'success')
                                that.$common.DelayedBack();
                            } else {
                                that.$common.message('修改失败,库存数量需要大于0', 'warning', 2000)
                            }
                        })
                    }).catch(() => { })
                }
            })
        },
    }
}

</script>
<style lang='less' scoped>
.ModifyInventory {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .ProductLibrary_Add_Form {
            border-radius: 10px;
            // border: 1px solid red;

            .formTitle {
                width: 100%;
                margin-bottom: 22px;
                border-left: 4px solid #0363FA;
                padding-left: 10px;
                font-size: 24px;
                font-weight: 500;
                color: #333333;
            }
        }

        .submitBtn{
            width: 160px;
            height: 60px;
            border-radius: 10px;
            background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
            text-align: center;
            color: #fff;
            line-height: 60px;
            cursor: pointer;
        }

    }


}</style>
