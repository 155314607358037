// 货币选项 -- 计价货币 、运费货币
let CurrencyOption = [{
  label: "人民币(￥)",
  value: "￥"
}, {
  label: "USD($)",
  value: "$"
}];
let nameNumberOption = [{
  label: '名称',
  value: '0'
}, {
  label: '编号',
  value: '1'
}];

// 财务统计开始
let AssetChangesOptions = [{//资产变化
  label: '未选择',
  value: ''
}, {
  label: '增加',
  value: '+'
}, {
  label: '减少',
  value: '-'
}];
// 资产变化-增加
let ChangeReasonOptionsAdd = [{
  label: '支出取消',
  value: 'CINP'
}, {
  label: '产品报废',
  value: 'OUT0'
}, {
  label: '其他出库',
  value: 'OUT1'
}, {
  label: '供应商退款',
  value: 'OUT2'
}, {
  label: '销售收入',
  value: 'SAOT0A'
}]
// 资产变化-减少
let ChangeReasonOptionsReduce = [{
  label: '采购支出',
  value: 'INP0'
}, {
  label: '采购支出(其他)',
  value: 'INP1'
}, {
  label: '取消出库',
  value: 'COUT'
}, {
  label: '运费支出（未完成订单）',
  value: 'FA'
}, {
  label: '运费支出（已完成订单）',
  value: 'FAC'
}, {
  label: '运费支出（退货单）',
  value: 'FAG'
}, {
  label: '退款支出',
  value: 'SAIN0A'
}]
// 财务统计结束

export {
  CurrencyOption, nameNumberOption,
  AssetChangesOptions,
  ChangeReasonOptionsAdd,
  ChangeReasonOptionsReduce
}